/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-27 17:55:28
 * @FilePath: /AIGo.work/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import chat from './chat'
import tool from './tool'
import editApp from './editApp'
import orgPermission from './orgPermission'
import comment from './comment'
import article from './article'

import createPersistedState from 'vuex-persistedstate'
import REQUEST from '@/api/http'
import { isMobile } from '@/utils'
import { setToken } from '@/service/login'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    total: 0,
    gpt3Total: null,//gpt3剩余次数
    gpt4Total: null,//gpt4剩余次数
    scoket: {
      color:'green',
      scoketText:'连接成功'
    },
    userInfo:{},
    lsTotals: 0,
    openMenu:false,
    messages:[],
    avatar:'',
    murmur:'',
    notice:'',
    loading:false,
    vipInfo: {},
    loginVisible:false,//是否显示登录弹窗
    curOptmizeLogIdMap:{},//当前会话的优化时需要的logId
    isInterfaceGetMsg:false,//是否正在用接口获取回答
    curPosterImgBase64:"",//下载海报的时候，跳转前，先把图片base64数据保存在这儿

//-------------------------------------------------
    chatMenuListA:[],//左侧列表的数据
    chatMenuListB:[],//左侧列表的数据
    curTalkDetailList:[],//当前显示的对话记录

    haveUserInfo:false,//是否已经加载用户信息了

    token:null,

    newHome_2_home_text:"",//从新首页到原来的首页，携带的文字
    newHome_2_home_isOptmize:false,

    nickName:"",

    createAppName:"",
    createAppType:1,

    eventType:"",

    userId:null,
    orgId:null,
    orgTag:null,
    docId:null,
    isDomain: false,
    pluginId:null,
    toolId:null,

    workflowFirstLoad: true, //工作流首次加载
    workflowConfig: {}, //工作流各类选项类型定义
    nodeChange: false, //工作流节点更新状态
    nodeRefChange: false, //工作流引用节点更新状态
    nodeCodeChange: false,
    nodeInfo: {}, //工作流节点信息
    nodeRun: false, //工作流节点运行状态
    nodeRunInfo: {}, //工作流节点运行信息
    isIntact: false, //工作流节点参数状态（数据是否完整)
    paramChange: false, //工作流节点可引用参数变化
    nodeCanRefParam: {}, //工作流节点可引用参数集合
    codeChange: false, //工作流代码节点数据变化监听
    nodeChangeParam: {}, //工作流节点修改的参数
    showNodeMenu: null, //工作流节点操作菜单状态

    target: {}, //对话目标信息

    uqestionInputAble:true,//底部输入框是否可以输入

    isAskInto:false,//是否是从首页点提问按钮进来的
    homeIntoLogId:null,//在首页选应用时创建的对话的id

    homeTabIndex:-1,//首页的tab选中的序号

    isNoLeftMenu:false,//为true表示左侧栏是空的，要做特殊处理

    monthlyReturnRouter:'/',

    rechargeReturnRouter:'/',
    
    returnRouter:'/',

    selfScrollTop:0,
    
    showTab:1,

    shareId:'',
    /*
      当前打开的应用所对应的组织的id，
      如果当前的应用不属于某个组织，则值为'0'
      调接口的时候要判断这个值， 不是‘0’的话要传这个
      跳转的时候要判断这个值，不是‘0’的话要传这个
      离开应用页面后，要把这个值设为'0'
    */
    curOpenAppOrgid:'0',

    //是否显示绑定手机弹窗
    // afterQrcodeShow:false,

    isLogin:false,

    //是否显示图片输入框
    showImgInoutPanel:false,

    //顶部邀请赚钱按钮是否显示
    topInviteBtnShow:true,

    //顶部vip入口按钮是否显示
    topVipBtnShow:true,

    keepAlivePage:'MenberHome',
    
    checkList:[],
    
    checkChatList:[],

    appId:'',

    articleInfo: {},

    orgInfo:{},

    isXmrc: false,

    showMenu: true,
    terminal:'',
    activeIndex:'',
    showNavbar: true,
    tabActive:'',
    appInfo:{},
    appSwitch:[],
    questionId:'',
    instruction:null,
    selectedFile:{},
    sharePlatform:'',
  },
  getters,
  mutations: {
    SET_OPEN: (state, data) => {
      state.drawer = data
    },
    SET_LOADING: (state, data) => {
      state.loading = data
    },
    SET_MURMUR: (state, data) => {
      state.murmur = data
    },
    SET_TOTAL: (state, data) => {
      state.total = data
    },

    SET_GPT3_TOTAL: (state, data) => {
      state.gpt3Total = data;
    },
    SET_GPT4_TOTAL: (state, data) => {
      state.gpt4Total = data;
    },

    //gpt3剩余次数减1
    SET_GPT3_TOTAL_MINUS: (state) => {
        state.gpt3Total --
    },
    //gpt4剩余次数减1
    SET_GPT4_TOTAL_MINUS: (state) => {
        state.gpt4Total --
    },

    SET_AVATAR: (state, data) => {
      state.avatar = data
    },
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
    },
    SET_LS_TOTALS: (state, data) => {
      state.lsTotals = data
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
    },
    SET_OPENMENU: (state, data) => {
      state.openMenu = data
    },
    SET_MESSAGES: (state, data) => {
      state.messages = data
    },
    SET_SCOKET: (state, data) => {
      state.scoket = data
    },
    SET_OPEN_MENU: (state, data) => {
      state.openMenu = data
    },
    SET_NOTICE:(state,data)=>{
      state.notice = data
    },
    SET_VIP_INFO:(state,data)=>{
      state.vipInfo = data
    },
    SET_LOGIN_VISIBLE:(state,data)=>{
      state.loginVisible = data
    },
    SET_IS_INTERFACE_GET_MSG:(state,data)=>{
      state.isInterfaceGetMsg = data
    },

    GET_CUR_POSTER_IMG_BASE64:(state,data)=>{
      state.curPosterImgBase64 = data
    },

    SET_chatMenuListA: (state, data) => {
        state.chatMenuListA = data
    },
    SET_chatMenuListB: (state, data) => {
        state.chatMenuListB = data
    },
    SET_curTalkDetailList: (state, data) => {
        state.curTalkDetailList = data
    },

    add_chatMenuListA_item: (state, data)=>{
        state.chatMenuListA.unshift(data)
    },

    add_chatMenuListB_item: (state, data)=>{
        state.chatMenuListB.unshift(data)
    },

    //往gpt3.5菜单里添加数组
    add_chatMenuListA_List: (state, data)=>{
        state.chatMenuListA = state.chatMenuListA.concat(data)
    },

    //往gpt4菜单里添加数组
    add_chatMenuListB_List: (state, data)=>{
        state.chatMenuListB = state.chatMenuListB.concat(data)
    },

    SET_HAVE_USER_INF: (state, data)=>{
      state.haveUserInfo = data
    },

    SET_TOKEN: (state, data)=>{
      state.token = data
    },

    SET_newHome_2_home_text: (state, data)=>{
      state.newHome_2_home_text = data
    },

    SET_newHome_2_home_isOptmize: (state, data)=>{
      state.newHome_2_home_isOptmize = data
    },

    SET_nickName: (state, data)=>{
      state.nickName = data
    },

    SET_createAppName: (state, data)=>{
      state.createAppName = data
    },

    SET_createAppType: (state, data)=>{
      state.createAppType = data
    }, 

    SET_userId: (state, data)=>{
      state.userId = data
    },
    
    SET_docId: (state, data)=>{
        state.docId = data
    },
    
    SET_isDomain: (state, data)=>{
        state.isDomain = data
    },
    
    SET_orgId: (state, data)=>{
        state.orgId = data
    },

    SET_orgTag: (state, data)=>{
        state.orgTag = data
    },
    
    SET_pluginId: (state, data)=>{
        state.pluginId = data
    },

    SET_toolId: (state, data)=>{
        state.toolId = data
    },

    SET_workflowFirstLoad: (state, data)=>{
        state.workflowFirstLoad = data
    },
    
    SET_workflowConfig: (state, data)=>{
        state.workflowConfig = data
    },
    
    SET_nodeRefChange: (state, data)=>{
        state.nodeRefChange = data
    },
    
    SET_nodeCodeChange: (state, data)=>{
        state.nodeCodeChange = data
    },
    
    SET_nodeChange: (state, data)=>{
        state.nodeChange = data
    },

    SET_nodeChangeParam: (state, data)=>{
        state.nodeChangeParam = data
    },

    SET_nodeInfo: (state, data)=>{
        state.nodeInfo = data
    },
    
    SET_nodeRun: (state, data)=>{
        state.nodeRun = data
    },
    
    SET_nodeRunInfo: (state, data)=>{
        state.nodeRunInfo = data
    },

    SET_isIntact: (state, data)=>{
        state.isIntact = data
    },
    
    SET_paramChange: (state, data)=>{
        state.paramChange = data
    },

    SET_nodeCanRefParam: (state, data)=>{
        state.nodeCanRefParam = data
    },
    
    SET_codeChange: (state, data)=>{
        state.codeChange = data
    },
    
    SET_showNodeMenu: (state, data)=>{
        state.showNodeMenu = data
    },

    SET_target: (state, data)=>{
        state.target = data
    },

    SET_uqestionInputAble: (state, data)=>{
      state.uqestionInputAble = data
    }, 
    
    SET_isAskInto: (state, data)=>{
      state.isAskInto = data
    },

    SET_homeIntoLogId: (state, data)=>{
      state.homeIntoLogId = data
    },

    SET_homeTabIndex: (state, data)=>{
      state.homeTabIndex = data
    },

    SET_isNoLeftMenu: (state, data)=>{
        state.isNoLeftMenu = data
    },

    SET_monthlyReturnRouter: (state, data)=>{
        state.monthlyReturnRouter = data
    },

    SET_rechargeReturnRouter: (state, data)=>{
        state.rechargeReturnRouter = data
    },

    SET_returnRouter: (state, data)=>{
        state.returnRouter = data
    },

    SET_curOpenAppOrgid: (state, data)=>{
        state.curOpenAppOrgid = data
    },

    SET_eventType: (state, data)=>{
        state.eventType = data
    },

    SET_isLogin: (state, data)=>{
        state.isLogin = data
    },

    SET_showImgInoutPanel: (state, data)=>{
        state.showImgInoutPanel = data
    },

    SET_topInviteBtnShow: (state, data)=>{
        state.topInviteBtnShow = data
    },

    SET_topVipBtnShow: (state, data)=>{
        state.topVipBtnShow = data
    },

    SET_keepAlivePage: (state, data)=>{
        state.keepAlivePage = data
    },

    SET_selfScrollTop: (state, data)=>{
        state.selfScrollTop = data
    },

    SET_showTab: (state, data)=>{
        state.showTab = data
    },

    SET_shareId: (state, data)=>{
        state.shareId = data
    },
    
    SET_checkList: (state, data)=>{
        state.checkList = data
    },

    SET_checkChatList: (state, data)=>{
        state.checkChatList = data
    },
    
    SET_appId: (state, data)=>{
        state.appId = data
    },

    SET_appSwitch: (state, data)=>{
        state.appSwitch = data
    },

    SET_appInfo: (state, data)=>{
        state.appInfo = data
    },
    
    SET_articleInfo: (state, data)=>{
        state.articleInfo = data
    },
    
    SET_orgInfo: (state, data)=>{
        state.orgInfo = data
    },

    SET_isXmrc: (state, data) => {
      state.isXmrc = data
    },

    SET_showMenu: (state, data) => {
        state.showMenu = data
    },

    SET_terminal: (state, data) => {
      state.terminal = data
    },

    SET_activeIndex: (state, data) => {
      state.activeIndex = data
    },

    SET_showNavbar: (state, data) => {
      state.showNavbar = data
    },
    
    SET_tabActive: (state, data) => {
      state.tabActive = data
    },
    
    SET_questionId: (state, data) => {
      state.questionId = data
    },
    
    SET_instruction: (state, data) => {
      state.instruction = data
    },
    
    SET_selectedFile: (state, data) => {
      state.selectedFile = data
    },
    
    SET_sharePlatform: (state, data) => {
      state.sharePlatform = data
    },
    
    
  },
  actions: {
    openDrawer({commit}, data) {
      commit('SET_OPEN', data)
    },
    SET_OPENMENU: (state, data) => {
      state.openMenu = data
    },
    SET_SCOKET: (state, data) => {
      state.scoket = data
    },
    SET_OPTMIZE_LOGID: (state, {chatLogId, opt_logId})=>{
    //   console.log("=====SET_OPTMIZE_LOGID===")
    //   console.log(state.state.curOptmizeLogIdMap)
      state.state.curOptmizeLogIdMap[chatLogId] = opt_logId
    },

    loadHomeData: ({commit})=>{
      return new Promise(async (resolve, reject)=>{

        //gpt3.5传1， gpt4.0传8
          var res = await REQUEST.POST("HOME_V2", {"sendType": 1})
        //   console.log("homev2返回", res)
          
        //   commit("SET_chatMenuListA", res.data.logList)

          commit('SET_TOTAL', res.data.remainingTimes)
          commit('SET_GPT3_TOTAL', res.data.remainingTimesGpt3)
          commit('SET_GPT4_TOTAL', res.data.remainingTimesGpt4)
          commit('SET_AVATAR', res.data.avatar)
          commit('SET_NOTICE', res.data.announcement)
          commit('SET_HAVE_USER_INF', true)
          commit('SET_nickName', res.data.name)

          commit('SET_userId', res.data.id)

          commit('SET_isLogin', true)
          
          resolve()

      })
    },

    //加载左侧gpt3.5的菜单
    loadLeftMenuGpt3:({commit}, {pageNumber, pageSize})=>{
        return new Promise(async (resolve, reject)=>{
            //sendType: gpt3.5传1， gpt4.0传8
            var res = await REQUEST.POST("GET_QUERY_MESSAGE_LOGS", {sendType: 1, pageNumber, pageSize})
            // console.log("加载左侧gpt3.5", res)
            if(res.data){
              commit("add_chatMenuListA_List", res.data)
              if(res.data.length < pageSize){
                  resolve({end:true})
              }else{
                  resolve({end:false})
              }
            }else{
              commit("add_chatMenuListA_List", [])
              resolve({end:true})
            }

        })
    },

    //加载左侧gpt4的菜单
    loadLeftMenuGpt4:({commit}, {pageNumber, pageSize})=>{
        return new Promise(async (resolve, reject)=>{
            //sendType: gpt3.5传1， gpt4.0传8
            var res = await REQUEST.POST("GET_QUERY_MESSAGE_LOGS", {sendType: 8, pageNumber, pageSize})
            // console.log("加载左侧gpt4返回", res)
            if(res.data){
              commit("add_chatMenuListB_List", res.data)
              if(res.data.length < pageSize){
                  resolve({end:true})
              }else{
                  resolve({end:false})
              }
            }else{
              commit("add_chatMenuListB_List", [])
              resolve({end:true})
            }

        })
    },

    anonymousLogin:({commit}) =>{
        return new Promise(async (resolve, reject)=>{
            
            REQUEST.POST('LOGIN', {mobile:'temp', password:'temp', freeLogin:true}).then(res => {
                if (res.status == 200) {
                  commit('SET_isXmrc', res.data.isXmrc)
                  commit('SET_TOKEN', res.data.token)
                  setToken(res.data.token)
    
                  commit('SET_HAVE_USER_INF', false)

                  if (isMobile()) {
                    window.localStorage.setItem('phone', true)
                  } else {
                    window.localStorage.setItem('phone', false)
                  }
                  resolve()

                }
            })

        })
    }

  },
  modules: {chat, tool, editApp, orgPermission, comment, article},
  plugins: [
    createPersistedState({
        storage: window.localStorage,
        paths:['appSwitch','appInfo',"token",'userId','orgId','keepAlivePage','selfScrollTop',
            'returnRouter','workflowConfig','pluginId','toolId','haveUserInfo','nickName','avatar',
            'target','showTab','shareId','appId','articleInfo','orgInfo','isXmrc','terminal','activeIndex',
            'showNavbar','sharePlatform','showMenu'
        ]
    })
  ]
})
