<template>

<el-dialog title="会员信息" :visible.sync="visible"             :before-close="handleClose"  

                 :width="width" append-to-body
                 center class="vipDialog" :close-on-click-modal="false">


          <!--form-->
          <VconsoleSwitch class="vconsoleSwitch"></VconsoleSwitch> 

              <div class="topWrap">
                <div>
                  <span class="myPackage">我的套餐：</span>
                  <span class="memberType">{{ vipInfo.name }}</span>
                </div>
                <div class="buyBtn" @click="buyBtnClick">购买会员</div>
              </div>

              <div class="vipInfoWrap">
                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.name == '免费版'">永久免费</span>
                  <span class="infoText" v-else>到期时间：{{ vipInfo.expiryTime }} </span>
                </div>
                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.times >= 0">支持AI3.5，每天{{ vipInfo.times }}次</span>
                  <span class="infoText" v-else>支持AI3.5，无限次数</span>
                </div>
                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.limitToken >= 0">{{ vipInfo.limitToken }}个token(AI3.5)</span>
                  <span class="infoText" v-else>不限token(AI3.5)</span>
                </div>

                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText" v-if="vipInfo.suGpt4">
                    <template v-if="vipInfo.limitTimesGpt4 >= 0">
                      支持AI4.0，每天赠送{{ vipInfo.limitTimesGpt4 }}次
                    </template>
                    <template v-else>
                      支持AI4.0，次数不限
                    </template>
                  </span>
                  <span v-else class="infoText">不支持AI4.0</span>
                </div>

                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText">{{ vipInfo.nodeCount }}个节点</span>
                </div>

                <div class="item">
                  <img src="../../assets/yes.png">
                  <span class="infoText">上下文：{{ vipInfo.contextCount }}</span>
                </div>

              </div>

              <div class="useInfo">使用信息</div>
              <div class="myVipInfoWrap">
                <div class="vipInfoItem">今日AI3.5剩余：
                    <span v-if="gpt3Total && gpt3Total >= 0">{{ gpt3Total }}次</span>
                    <span v-else>不限次数</span>
                </div>
                <div class="vipInfoItem">今日AI4.0剩余：
                    <span v-if="gpt4Total && gpt4Total >= 0">{{ gpt4Total }}次</span>
                    <span v-else>不限次数</span>
                </div>
              </div>
          <span slot="footer" class="dialog-footer">
              <el-button @click="handleClose">关 闭</el-button>
          </span>
      </el-dialog>

</template>
  
<script>
import { getQueryStrByUrl, getQueryString, isMobile, isTel, routerPush } from '@/utils'
import { mapState } from 'vuex'
import store from '@/store'
import router from '@/router'
import Botton from "@/components/button/Button.vue"
import REQUEST from '@/api/http'
import VconsoleSwitch from '@/components/vconsoleSwitch.vue'
import { getToken } from '@/service/login';
 
export default {
    components: {Botton, VconsoleSwitch},
    data() {
        return {
            vipInfo: {},

        }
    },
    props:{
        visible:Boolean
    },
    async mounted(){
    },
    watch: {
        visible(){
            if(this.visible){
                this.getVipInfo()
            }
        }
    },
    computed:{
    ...mapState(['scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', , 'nickName', 'afterQrcodeShow', 'topInviteBtnShow']),

        ...mapState({
        }),
        width(){
            if(isMobile()){
                return "90%"
            }
            return "400px"
        }

    },
    methods:{
        handleClose(){

            this.$emit('update:visible', false)
        },
        getVipInfo() {
            if (!getToken()){
                return
            }
            this.$https.get('VIP_INFO', {}).then(res => {
                if (res.status == 200) {
                    console.log("vipinfo返回", res.data)
                    this.vipInfo = res.data;
                    if(this.vipInfo.expiryTime){
                    this.vipInfo.expiryTime = this.vipInfo.expiryTime.split(" ")[0]

                    }

                }
            })
        },
        buyBtnClick(){
            var userCode = getQueryString("userCode")
            if(userCode){
                window.open("/product?userCode=" + userCode, '_blank')

            }else{
                window.open("/product", '_blank')
            }
        },

    }
}
</script>
  
<style scoped lang="scss">

.topWrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;

    .myPackage{
      font-size: 15px;
      font-weight: bold;
    }
    .memberType{
      color: #45c084;
      font-size: 15px;
    }
    .time{
      color: #777;
      padding-left: 20px;
      font-size: 13px;

    }
    .buyBtn{
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: #45c084;;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
    }
  }

  .vipInfoWrap{
    padding: 22px;
    border-radius: 12px;
    background: rgb(242, 243, 245);
    .item{
      padding: 4px 0;
      position: relative;
    }
    img{
      width: 10px;
      height: 11px;
      position: relative;
      top: 2px;
    }
    .infoText{
      padding-left: 8px;
    }
  }
  .useInfo{
    font-size: 15px;
    color: #000;
    font-weight: bold;
    padding: 15px 0 5px;
  }
  .myVipInfoWrap{
      padding: 22px;
      border-radius: 12px;
      background: rgb(242, 243, 245);
      .vipInfoItem{
        padding: 6px 0;
      }
  }
@media screen and (max-width: 900px) {



}

</style>