<template>
    <div>
        <div v-if="!loading">
            <div v-if="!showSet">
                <div v-if="showOrg">
                    <div class="homeBtnWrap">
                        <el-button type="text" @click="goHomeBtnClick">{{showHomeTitle}}</el-button>
                    </div>
                    <div class="header">
                        <img class="topImg" :src="topImg">
                        <div class="headerRight">
                            <div class="headerTitle">{{ topName }}</div>
                            <div :class="showMore?'headerDesMore':'headerDes'" >
                                <span v-if="summary">{{ summary }}</span>
                                <span v-else>
                                    <span>{{ summaryFront }}</span>
                                    <span class="linkStyle" @click="openWin">www.kpz.cc</span>
                                    <span>{{ summaryAfter }}</span>
                                </span>
                            </div>
                            <div class="headerFooter" @click="doShow">
                                <span v-if="!showMore">更多</span>
                                <span v-else>收起</span>
                            </div>
                            
                        </div>
                        <div class="managerBtn" v-if="baseInfo&&isManager" @click="managerBtnClick">管理星舰</div>
                    </div>
                    <div class="infoWrap" @click="vipBtnClick" v-if="!isManager&&showStatus&&!isXmrc">
                        <div class="infoWrapLeft">
                            <li class="iconfont icon-huiyuan leftIcon"></li>
                            <div class="textTitle">{{ vipName }} &nbsp;&nbsp;{{ vipEndTime }}</div>
                        </div>
                        <div class="infoWrapRight">
                            <div class="sjBtn">升级或续费</div>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="line1"></div>
                </div>
                <div v-else class="lineDom"></div>
                <div class="infoWrapA infoWrap"  @click="infoClick">
                    <div class="infoWrapLeft">
                        <img class="avatar" :src="avatar">
                        <div class="nickName">{{ nickName }}</div>
                    </div>
                    <div class="infoWrapRight">
                        <div class="updateText">修改</div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="infoWrap" @click="myAigoMoney" v-if="!isXmrc">
                    <div class="infoWrapLeft">
                        <i class="el-icon-help leftIcon"></i>
                        <div class="textTitle">{{ doNumShow(myMoney) + " (星舰币)" }}</div>
                    </div>
                    <div class="infoWrapRight">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="infoWrap" @click="myOrgClick" v-if="!isXmrc">
                    <div class="infoWrapLeft">
                        <i class="el-icon-coordinate leftIcon"></i>
                        <div class="textTitle">我的星舰（组织）</div>
                    </div>
                    <div class="infoWrapRight">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div v-if="!isOrg">
                    <div class="infoWrap" @click="inviteBtnClick" v-if="!isXmrc">
                        <div class="infoWrapLeft">
                            <i class="el-icon-user leftIcon"></i>
                            <div class="textTitle">邀请赚钱</div>
                        </div>
                        <div class="infoWrapRight">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="infoWrap" @click="setBtnClick" v-if="!isXmrc">
                    <div class="infoWrapLeft">
                        <i class="el-icon-setting leftIcon"></i>
                        <div class="textTitle">设置</div>
                    </div>
                    <div class="infoWrapRight">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="line1"></div>
                <div class="infoWrap infoWrapLast" @click="logoutBtnClick">
                    <div class="infoWrapLeft">
                        <i class="el-icon-switch-button leftIcon"></i>
                        <div class="textTitle">退出登录</div>
                    </div>
                    <div class="infoWrapRight">
                    </div>
                </div>
            </div>
            <div class="info-set-box" v-else>
                <div class="backBtnWrap">
                    <el-button type="text" @click="close">返回</el-button>
                </div>
                <div>
                    <div class="info-title">隐私</div>
                    <div class="infoWrap" @click="routingJump('privacy')">
                        <div class="infoWrapLeft">
                            <i class="el-icon-user leftIcon"></i>
                            <div class="textTitle">隐私政策</div>
                        </div>
                        <div class="infoWrapRight">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="infoWrap" @click="routingJump('userServices')">
                        <div class="infoWrapLeft">
                            <i class="el-icon-service leftIcon"></i>
                            <div class="textTitle">用户协议</div>
                        </div>
                        <div class="infoWrapRight">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="infoWrap" @click="routingJump('captain')">
                        <div class="infoWrapLeft">
                            <i class="el-icon-place leftIcon"></i>
                            <div class="textTitle">舰长协议</div>
                        </div>
                        <div class="infoWrapRight">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <layOut ref="layout"></layOut>
            <myInfoDialog :visible.sync="myInfoDialogVisible" ref="myInfoDialogRef"></myInfoDialog>
            <personVipDialog :visible.sync="personVipDialogVisible"></personVipDialog>
            <MenberVipDialog :visible.sync="MenberVipDialogVisible" ref="mMenberVipDialogRef"></MenberVipDialog>
            <RechargeDialog :visible.sync="RechargeDialogVisible" v-if="!inWx"></RechargeDialog>
        </div>
        <div v-else>
            <el-empty :image="require('@/assets/loading.png')" description="加载中"></el-empty>
        </div>
    </div>
</template>

<script>
import REQUEST from '@/api/http';
import { mapState } from 'vuex'
import layOut from '@/layout/layOut.vue';
import myInfoDialog from './myInfoDialog.vue';
import { isMobile, isWx, routerPush } from '@/utils'
import personVipDialog from '@/views/home/personVipDialog.vue';
import VconsoleSwitch from '@/components/vconsoleSwitch.vue';
import MenberVipDialog from '../menberVip/MenberVipDialog.vue';
import RechargeDialog from '@/views/recharge/RechargeDialog.vue';
import BigNumber from 'bignumber.js';
import { getToken } from '@/service/login';

export default {
components: { layOut, myInfoDialog, personVipDialog, VconsoleSwitch, MenberVipDialog, RechargeDialog },
data() {
   return {
       loading:true,
       showOrg:true,
       isOrg:false,
       baseInfo:null,
       topName:"",
       summary:"",
       summaryFront:"",
       summaryAfter:"",
       topImg:"",
       myInfoDialogVisible:false,
       personVipDialogVisible:false,
       MenberVipDialogVisible:false,
       vipName:"",
       vipEndTime:"",
       RechargeDialogVisible:false,
       myMoney:0,
       showMore: false,
       showHomeTitle: '主页',
       toPath: '/',
       nowOrgId: '',
       showStatus: true,
       showSet: false,
       isXmrc: false,
       terminal: '',
   }
},
computed:{
    ...mapState(['orgId','orgTag','scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', , 'nickName', 'afterQrcodeShow', 'topInviteBtnShow']),
    ...mapState([]),
    isManager(){
        var bool1 = this.$store.getters.havePermission(1)//"应用管理与知识库管理"
        var bool2 = this.$store.getters.havePermission(1024)//"成员管理"
        if(bool1 || bool2){
            return true
        }else{
            return false
        }
    },
    inWx(){
        // return true
        return isWx()
    }
},
// watch: {
//     $route: {
//         handler(to, from) {
//             if(to.name=='orgAppList'||to.name=='OrgannizationMain'){
//                 this.nowOrgId = this.$store.state.orgId
//                 if(this.nowOrgId == "1772944669140676610"){
//                     this.showStatus = false
//                 }else{
//                     this.showStatus = true
//                 }
//             }
//             this.start()
//       },
//       deep: true,
//     },
//     orgId(val) {
//         this.nowOrgId = val
//         this.start()
//     },
//     orgTag(val) {},
// },
async mounted() {
},
methods: {
    // 打开官网新窗口
    openWin() {
        window.open("https://www.kpz.cc");
    },
    // 官网信息显示操作
    doShow(){
        this.showMore = !this.showMore
    },
    // 星舰币数值显示处理
    doNumShow(value){
        if (!value) return '0';
        const numStr = value.toString();
        const numArr = numStr.split('.');
        const integer = numArr[0];
        const decimal = numArr.length > 1 ? '.' + numArr[1] : '';
        return `${this.format_number(integer)}${decimal}`;
    },
    //数值增加分隔符
    format_number(n) { 
        var b = parseInt(n).toString();
        var len = b.length;
        if (len <= 3) { return b; }
        var r = len % 3;
        return r > 0 ? b.slice(0, r) + "," + b.slice(r, len).match(/\d{3}/g).join(",") : b.slice(r, len).match(/\d{3}/g).join(",");
    },
    init(){
        this.loading = true
        this.terminal = localStorage.getItem('terminal')
        if(this.$route.params.id){
            this.nowOrgId = this.$route.params.id
        }else if(!this.$route.params.id&&this.$route.query.orgId){
            this.nowOrgId = this.$route.query.orgId
        }else{
            this.nowOrgId = this.$store.state.orgId
        }
        this.showMore = false
        this.showSet = false
        this.start()
    },
    async start(){
        this.showOrg = true
        let baseUrl = localStorage.getItem('baseRouter')
        if(baseUrl){
            this.isXmrc = baseUrl.includes("ai.xmgxrc.com")
        }else{
            this.isXmrc = location.href.includes("ai.xmgxrc.com")
        }
        localStorage.setItem('isXmrc', this.isXmrc)
        if(location.href.indexOf("/starship/") > 0 || location.href.indexOf("/ss/") > 0){
            await this.loadBaseInfo()
            this.$nextTick(()=>{
                if(this.isXmrc){
                    this.toPath = '/starship/' +this.baseInfo.tag
                    this.showHomeTitle = '星舰主页'
                }else{
                    this.toPath = '/ss/' +this.baseInfo.tag
                    this.showHomeTitle = '星舰宣传页'
                }
                this.isOrg = true
                this.topName = this.baseInfo.name
                this.topImg=this.baseInfo.icon
                this.summary=this.baseInfo.summary
                this.loadMemberInfo()
            })
        }else if((location.href.indexOf("/app/") > 0 || location.href.indexOf("/ArticleDetail/") > 0 || location.href.indexOf("/OrgannizationDetail/") > 0 || location.href.indexOf("/share") > 0
        || location.href.indexOf("/orgAppList/") > 0|| location.href.indexOf("/createApp/") > 0) && (location.href.indexOf("/OrgannizationMain") < 0&&this.nowOrgId!=0&&this.nowOrgId)){
            await this.loadBaseInfoByOrgId()
            this.$nextTick(()=>{
                if(this.isXmrc){
                    this.toPath = '/starship/' +this.baseInfo.tag
                    this.showHomeTitle = '星舰主页'
                }else{
                    this.toPath = '/ss/' +this.baseInfo.tag
                    this.showHomeTitle = '星舰宣传页'
                }
                this.isOrg = true
                this.topName = this.baseInfo.name
                this.topImg=this.baseInfo.icon
                this.summary=this.baseInfo.summary
                this.loadMemberInfo()
            })
        }else if((location.href.indexOf("/noPermission") > 0 )){
            this.showOrg = false
        }else{
            this.baseInfo = null
            if(this.terminal=='app'){
                this.toPath = '/appHome'
            }else{
                this.toPath = '/'
            }
            this.showHomeTitle = '主页'
            this.isOrg = false
            this.summary=''
            this.topName = "https://aigo.work"
            this.topImg="https://static.aigo.work/home/20230912-115637.png"
            this.summaryFront = " AIGo 平台拥有  职场AI助手、星舰AI社群、企业数智化三款AI产品。详细信息请查看我们的产品官网："
            this.summaryAfter = "，我们支持全球领先的多个大模型，支持AI对话、联网搜索AI、AI画图、AI图像分析、AI知识库训练、AI数字人、AI客服、AI知识库查询等AI能力！"
            this.getVipInfo()
        }
        this.getUserInfo()
        setTimeout(() => {
            this.loading = false
        }, 250);
    },
    logoutBtnClick(){
        // this.$emit("logout")
        this.$refs.layout.open()
    },
    inviteBtnClick(){
        this.$emit("invite")
        routerPush("/inviteIndex")
    },
    routingJump(path) {
        routerPush("/"+path)
        this.showSet = false
    },
    close() {
        this.showSet = false
    },
    setBtnClick() {
        this.showSet = true
    },
    myOrgClick(){
        this.$emit("myOrg")
        routerPush("/OrgannizationMain")
    
    },
    async loadBaseInfo(){
        var res = await REQUEST.GET_QUERY("getOrganizationByTag", {tag:this.$route.params.tag})
        this.baseInfo = res.data
        await this.$store.dispatch('loadMyPermission', {orgId: this.baseInfo.id})
        if(this.baseInfo&&this.baseInfo.id == "1772944669140676610"){
            this.showStatus = false
        }else{
            this.showStatus = true
        }
    },
    async loadBaseInfoByOrgId(){
        var res = await REQUEST.GET_QUERY('getOrganizationById', {id: this.nowOrgId, orgId: this.nowOrgId})
        this.baseInfo = res.data
        await this.$store.dispatch('loadMyPermission', {orgId: this.baseInfo.id})
        if(this.baseInfo&&this.baseInfo.id == "1772944669140676610"){
            this.showStatus = false
        }else{
            this.showStatus = true
        }
    },
    infoClick(){
        this.myInfoDialogVisible = true
        this.$nextTick(()=>{
            this.$refs.myInfoDialogRef.init()
        })
    },
    vipBtnClick(){
        if(this.isOrg){
            if(isMobile()){
                routerPush("/MenberVipPage/" + this.$route.params.tag)
            }else{
                this.MenberVipDialogVisible = true
                this.$nextTick(()=>{
                    this.$refs.mMenberVipDialogRef.init()
                })
            }
        }else{
            this.personVipDialogVisible = true
        }
    },
    managerBtnClick(){
        routerPush("/OrgannizationDetail/OrgannizationInfo/" + this.baseInfo.id)
        // if(this.isXmrc){
        //     routerPush("/OrgannizationDetail/OrgannizationInfo/" + this.baseInfo.id)
        // }else{
        //     if(this.isOrg){
        //         routerPush("/OrgannizationDetail/orgAppList/" + this.baseInfo.id)
        //     }
        // }
    },
    /**
     * 获取aigo会员信息
     */
    getVipInfo() {
        if (!getToken()){
            return
        }
        this.$https.get('VIP_INFO', {}).then(res => {
            if (res.status == 200) {
            // console.log("vipinfo返回", res.data)
            var vipInfo = res.data;
                if(vipInfo.expiryTime){
                    vipInfo.expiryTime = vipInfo.expiryTime.split(" ")[0]
                    this.vipEndTime = vipInfo.expiryTime + "到期"

                    this.vipName = vipInfo.name
                }else{
                    this.vipName = "未购买"
                    this.vipEndTime = ''
                }
            }else{
                this.vipName = "未购买"
                this.vipEndTime = ''
            }
        })
    },

    /**
     * 获取组织会员信息，判断当前用户是不是组织会员
     */
    async loadMemberInfo(){
        try{
            var res = await this.$https.get("getOrgMemberGoods", {goodsType:5, orgId:this.baseInfo.id})
            if(res?.data?.goodsOrgMemberVip){
                this.vipName = res.data.goodsOrgMemberVip.name
                this.vipEndTime = res.data.expireTime.split(" ")[0] + "到期"
            }else{
                this.vipName = "未购买"
                this.vipEndTime = ''
            }
        }catch(e){

        }
    },
    //点击我的余额按钮
    myAigoMoney(){
        if(this.inWx){
            this.$store.commit('SET_rechargeReturnRouter', this.$router.currentRoute.path)
            routerPush("/RechargeWx")
        }else{
            this.RechargeDialogVisible = true
        }
    },
    async getUserInfo(){
        try{
            var res = await this.$https('GET_USERINFO', {})
            if(res.data){
                this.myMoney = BigNumber(res.data.walletBalance).div(1000)
                this.$store.commit('SET_AVATAR', res.data.avatar)
                this.$store.commit('SET_nickName', res.data.name)
            }
        }catch(e){
            console.error(e);
        }
        
    },
    goHomeBtnClick(){
        routerPush(this.toPath)
        this.$emit("close")
    }
}
}
</script>

<style lang='scss' scoped>
.lineDom {
    margin: 20px 0;
}
.infoWrapA{
    height: 70px;
}
.infoWrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 60px;
    padding: 0 20px;
    cursor: pointer;
    border-bottom: 1px solid #f4f4f4;
    background: #fff;
    .infoWrapLeft{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .infoWrapRight{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333;
    }
}
.infoWrap:hover{
    background: #f6f6f6;
}
.infoWrapLast{
    border-bottom: none;
}
.avatar{
    width: 40px;
    height: 40px;
    border-radius: 40px;
}
.nickName{
    font-size: 13px;
    color: #666;
    padding-left: 6px;
}
.updateText{
    color: #666;
    font-size: 13px;

}
.textTitle{
    font-size: 13px;
    color: #666;
    padding-left: 5px;
}
.leftIcon{
    color: #666;
    font-size: 24px;
}
.line1{
    height: 5px;
    background: #f4f4f4;
}
.sjBtn{
    color: #b040cc;
    font-size: 13px;
}

.icon-huiyuan{
    color: #ffc64a;
    font-size: 26px;
}
.header{
    padding: 15px 30px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    .topImg{
        width: 70px;
        height: 70px;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .headerRight{
        flex-grow: 1;
        padding-left: 12px;
    }
    .headerTitle{
        font-size: 18px;
        color: #444;
    }
    .headerDes{
        font-size: 12px;
        color: #a3a3a3;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 设置行数，这里示例设置为2行 */
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        padding-top: 6px;
    }
    .headerDesMore{
        font-size: 12px;
        color: #a3a3a3;
        line-height: 22px;
        padding-top: 6px;
    }
    .linkStyle{
        color: #409EFF !important;
        cursor: pointer;
    }
    .headerFooter {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;
        font-size: 12px;
        color: #409EFF;
    }
}
.managerBtn{
    height: 33px;
    line-height: 33px;
    border-radius: 5px;
    background-color: rgba(255,255,255,1);
    color: rgba(142,142,147,1);
    font-size: 16px;
    text-align: center;
    position: absolute;
    right: 45px;
    top: -30px;
    border: 1px solid rgba(229,229,234,1);
    padding: 0 10px;
    cursor: pointer;
}
.managerBtn:hover{
    color: #8a72ff;
}
.homeBtnWrap{
    padding-left: 25px;
}
.backBtnWrap {
    position: absolute;
    top: 0;
    left: 15px;
}
.info-set-box{
    margin-top: 35px;
    background: #ededed;
}
.info-title{
    height: 35px;
    background: #ededed;
    color: #4a4a4a;
    margin: 5px 0;
    padding: 0 25px;
    display: flex;
    align-items: center;
    font-size: 15px;
    // font-weight: bold;
}
::v-deep .el-empty__image{
    width: 70px;
}
@media screen and (max-width: 900px){
}

</style>