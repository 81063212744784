<template>

    <el-dialog :visible.sync="visible" 
            :before-close="handleClose"  
            :width="width"
            center
            :close-on-click-modal="false"
            title="修改密码"
            :show-close="!loading"
            append-to-body>

            <div>
                <el-form :model="regform"
                    :rules="regrules"
                    ref="regform"
                    label-width="0px"
                    class="demo-ruleForm">

                    <el-form-item prop="mobile">
                        <el-input
                            auto-complete="new-password"
                            prefix-icon="el-icon-mobile-phone"
                            placeholder="请输入手机号"
                            v-model="regform.mobile">
                        </el-input>
                    </el-form-item>
            
                    <el-form-item class="mobilCodeItem" prop="vcode">
                        <el-input
                            class="ipt"
                            autoComplete="new-password"
                            prefix-icon="el-icon-notebook-2"
                            placeholder="请输入短信验证码"
                            v-model="regform.vcode">
                        </el-input>
                        <div v-if="codeTime <= 0" type="primary" class="getMobilCodeBtn" @click="mobilCodeBtnClick">短信验证</div>
                        <div v-else type="primary" class="getMobilCodeBtn getMobilCodeBtnGray">重新发送({{ codeTime }})</div>
                    </el-form-item>
            
            
                    <el-form-item prop="password">
                        <el-input class="ipt"
                            autoComplete="new-password"
                            type="password"
                            prefix-icon="el-icon-s-goods"
                            placeholder="请输入密码"
                            v-model="regform.password"></el-input>
                    </el-form-item>
            
                    <el-form-item prop="newPassword">
                        <el-input class="ipt"
                            autoComplete="new-password"
                            type="password"
                            prefix-icon="el-icon-s-goods"
                            placeholder="重复输入密码"
                            v-model="regform.newPassword"></el-input>
                    </el-form-item>
            
                    <el-form-item style="margin-bottom: 0;">
                        <el-button type="primary" @click="sureClick"  style="width:100%">修改</el-button>
                    </el-form-item>
                </el-form>
            </div>


    </el-dialog>


  </template>
  
  <script>
  import SlideVerify from '@/components/SlideVerify/index.vue'
  import {getQueryString, isTel, isMobile, routerPush } from '../utils/index'
import { setToken } from '@/service/login'
  
  export default {
    components: { SlideVerify },
    props:["openId", "hideLoginBtn", "visible"],
    data() {
  
      var checkMobil = (rule, value, callback)=>{
        if (!value) {
          return callback(new Error('手机号不能为空'));
        }
  
        var bool = isTel(value)
        if(bool){
          callback()
        }else{
          callback(new Error('手机号格式不正确'))
        }
      }
  
      var checPassword = (rule, value, callback)=>{
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.regform.newPassword !== '') {
            this.$refs.regform.validateField('newPassword');
          }
          callback();
        }
      }
  
      var checRePassword = (rule, value, callback)=>{
  
        // console.log("value", value)
        // console.log("this.regform.password", this.regform.password)
  
  
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.regform.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      }
      return {
        regform: {
          mobile: '',
          vcode:"",
          password: '',
          newPassword: "",
        },
        message: '',
        disabled: false,
        timer: null,
        showYzMode: false,
        showSlide: false,
        codeText: '获取验证码',
        regrules: {
          name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
          password: [{ validator:checPassword , trigger: 'blur' }],
          mobile: [
            // { required: true, message: '请输入手机号', trigger: 'blur' },
            { validator:checkMobil , trigger: 'blur' }
          ],
          vcode:[{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
          newPassword:[{ validator:checRePassword , trigger: 'blur' }]
        },
        codeTime:false,//倒计时时间
        codeTimeTotal:60,//两次获取验证码时间间隔必须大于这个数
        intervalId:null,
        loading:false
      }
    },
    mounted() {
      this.regform.browserFingerprint = window.localStorage.getItem('murmur')
    },
    beforeDestroy(){
      clearInterval(this.intervalId)
    },
    computed:{
        width(){
            if(isMobile()){
                return "95%"
            }
            return "400px"
        },
    },
    methods: {
      
      sureClick() {
        this.$refs.regform.validate(valid => {
          if (valid) {
            if (this.showYzMode == true) {
              this.showSlide = true
            } else {
  
              var userCode = getQueryString("userCode")
              if(userCode){
                this.regform.inviteCode = userCode
              }
  
              if(this.openId){
                this.regform.fromUserName = this.openId
              }
              
              this.$https('MODIFY_PASSWORD', this.regform).then(res => {
                if (res.status == 200) {
                  this.$message.success('修改密码成功！')
  
                // if(this.openId){
                    
                    // this.$store.commit('SET_TOKEN', res.data.token)
                    // this.$store.commit('SET_HAVE_USER_INF', false)
                    // this.$store.dispatch('loadHomeData')
                    // this.$router.push("/")
                // }
  
                    // this.$emit('chanePage')
                    let isXmrc = JSON.parse(JSON.stringify(this.$store.state.isXmrc))
                    this.$store.commit('SET_TOKEN', null)
                    setToken(null)
                    this.$store.commit('SET_HAVE_USER_INF', false)

                    this.$store.commit('SET_TOTAL', '')
                    this.$store.commit('SET_GPT3_TOTAL', '')
                    this.$store.commit('SET_GPT4_TOTAL', '')
                    this.$store.commit('SET_AVATAR', '')
                    this.$store.commit('SET_NOTICE', '')
                    this.$store.commit('SET_HAVE_USER_INF', '')
                    this.$store.commit('SET_nickName', '')
                    this.$store.commit('SET_userId', '')

                    // window.localStorage.clear()
                    this.$store.commit('SET_isXmrc', isXmrc)

                    this.$emit('update:visible', false)
                    this.$emit('modifyClose')

                    // routerPush("/")

                    // window.location.href="/"
                    // window.location.reload()

                }
              })
            }
          }
        })
      },
      success(data) { // 停用该方法！
        this.showSlide = false
        this.$https('ACCOUNT', this.regform).then(res => {
        //   console.log(res)
          if (res.status == 200) {
            this.$message.success('注册成功！')
            this.$emit('chanePage')
          }
        })
      },
      async mobilCodeBtnClick(){
        if(this.codeTime > 0){
          return
        }
  
        this.$refs.regform.validateField("mobile", async valid => {
          if (!valid) {
            // 发送验证码
  
            var res = await this.$https('GET_SEND_VCODE', {mobile: this.regform.mobile, type: 3})
            console.log("发送验证码返回", res)
  
            this.codeTime = this.codeTimeTotal
            this.intervalId = setInterval(() => {
              this.codeTime -= 1
              if(this.codeTime <= 0){
                clearInterval(this.intervalId)
              }
            }, 1000);
  
          }
        })
        
        
  
      },


        handleClose(){
            this.$emit('update:visible', false)
            this.$emit('modifyClose')
        },

    }
  }
  </script>
  
  <style scoped lang="scss">
  
  
  .mobilCodeItem ::v-deep .el-form-item__content{
    display: flex;
    flex-direction: row;
  }
  .getMobilCodeBtn{
    margin-left: 5px;
    width: 124px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #45c084;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
  }
  .getMobilCodeBtnGray{
    background: #eeeeee;
    color: #707070;
    cursor: default;
  }
  
  .ipt {
    ::deep .el-input__inner {
      -webkit-text-security:disc!important;
    } 
  }
  
  </style>